<template>
  <label
    :class="props.theme"
    :for="props.id"
  >
    <slot />
  </label>
</template>

<script setup>
// Props
const props = defineProps({
  id: {
    required: true,
    type: String,
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})
</script>

<style lang="postcss" scoped>
label {
  @apply text-sm leading-6 uppercase;

  &.dark {
    @apply text-white;
  }

  &.light {
    @apply text-winter-green-900;
  }
}
</style>
